<template>
  <div>
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link :to="CreateLink">
            {{ Name }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle"></Heading>
    <hr />
    <el-form
      :model="newTest"
      ref="newTest"
      label-width="80px"
      @submit.native.prevent
      @keyup.enter.native="createNew('newTest')"
    >
      <el-form-item label="Version" required v-if="isAdmin">
        <el-radio-group v-model="newTest.isNew">
          <el-radio-button :label="1">
            New Digital SAT
          </el-radio-button>
          <el-radio-button :label="0">
            Old Paper-Based SAT
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="type === 'practice'"
        label="Subject"
        required
        prop="subject"
      >
        <el-radio-group v-model="newTest.subject">
          <el-radio-button
            v-for="subject in subjectsNow"
            :key="subject.id"
            :label="subject.id"
          >
            {{ titleCase(subject.name) }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Name" required prop="title">
        <el-input
          v-model="newTest.title"
          placeholder="Name"
          maxlength="100"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="success" @click="createNew('newTest')"
          >Create Now</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import { instant } from "@ivy-way/material";
import SAT from "@/apis/sat.js";
import ExamActions from "@/apis/examActions.js";
import Common from "@/mixins/common.js";
import Theme from "@/common/theme";

export default {
  metaInfo() {
    return {
      title: `${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: { Breadcrumb },

  mixins: [Common],

  props: [],
  data() {
    return {
      newTest: {
        isNew: 1,
        subject: null,
        title: ""
      },
      subjects: []
    };
  },
  computed: {
    CreateLink() {
      let Link = "";
      if (
        this.$route.name === "SatAddPractice" ||
        this.$route.name === "TeacherSatAddPractice" ||
        this.$route.name === "CompanySatAddPractice"
      ) {
        let name = "";
        if (this.$route.name === "TeacherSatAddPractice") {
          name = "TeacherSatManagement";
        }
        if (this.$route.name === "CompanySatAddPractice") {
          name = "CompanySatManagement";
        }
        if (this.$route.name === "SatAddPractice") {
          name = "SatManagement";
        }
        if (this.$route.query.type && this.$route.query.type === "drill") {
          Link = {
            name: name,
            query: { type: "drill" }
          };
        } else {
          Link = {
            name: name,
            query: { type: "practice" }
          };
        }
      } else if (this.$route.name === "SatAddComposePractice") {
        Link = {
          name: "SatManagement",
          query: { type: "composePractice" }
        };
      }
      return Link;
    },
    Name() {
      let title = "";
      if (this.$route.name.indexOf("SatAddPractice") > -1) {
        if (this.$route.query.type && this.$route.query.type === "drill") {
          title = this.$t("sat.Drill Practice");
        } else {
          title = this.$t("sat.Partial Tests");
        }
      } else if (this.$route.name.indexOf("SatAddComposePractice") > -1) {
        title = this.$t("sat.Full Tests");
      }
      return title;
    },
    isTeacher() {
      return this.$route.name === "TeacherSatAddPractice";
    },
    isAdmin() {
      return this.$route.name === "SatAddPractice";
    },
    instant() {
      return instant;
    },
    myTitle() {
      let title = "";
      if (this.$route.name.indexOf("SatAddPractice") > -1) {
        if (this.$route.query.type && this.$route.query.type === "drill") {
          title = `Create ${this.$t("sat.Drill Practice")}`;
        } else {
          title = `Create ${this.$t("sat.Partial Tests")}`;
        }
      } else if (this.$route.name.indexOf("SatAddComposePractice") > -1) {
        title = `Create ${this.$t("sat.Full Tests")}`;
      } else if (this.$route.name.indexOf("SatAddAdaptive") > -1) {
        title = `Create ${this.$t("sat.Adaptive Tests")}`;
      }
      return title;
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    subjectsNow() {
      const OLD_PRACTICE_READING = 1;
      const OLD_PRACTICE_WRITING = 2;
      const OLD_PRACTICE_MATH = 3;
      const OLD_PRACTICE_MATH_CALC = 4;
      const OLD_DRILL_READING = 5;
      const OLD_DRILL_WRITING = 6;
      const OLD_DRILL_MATH = 7;
      const OLD_DRILL_MATH_CALC = 8;
      const OLD_FULL_TEST = 9;
      const OLD_ADAPTIVE_PRACTICE = 10;

      const DRILL_PRACTICE_ENGLISH = 11;
      const DRILL_PRACTICE_MATH = 12;
      const MODULE_PRACTICE_ENGLISH = 13;
      const MODULE_PRACTICE_MATH = 14;
      const FULL_TEST = 15;
      const ADAPTIVE_PRACTICE = 16;
      let subjects = [];
      if(this.$route.query.type == "drill"){

        if (this.newTest.isNew === 1) {
          subjects = [
            { id: 11, name: "english" },
            { id: 12, name: "math" }
          ];
        } else {
          subjects = [
            { id: 5, name: "reading" },
            { id: 6, name: "writing" },
            { id: 7, name: "math" },
            { id: 8, name: "math calculator" }
          ];
        }
      } else {
        if (this.newTest.isNew === 1) {
          subjects = [
            { id: 13, name: "english" },
            { id: 14, name: "math" }
          ];
        } else {
          subjects = [
            { id: 1, name: "reading" },
            { id: 2, name: "writing" },
            { id: 3, name: "math" },
            { id: 4, name: "math calculator" }
          ];
        }
      }
      return subjects;
    },
    type() {
      let type = "";
      if (this.$route.name.indexOf("SatAddPractice") > -1) {
        type = "practice";
      } else if (this.$route.name === "SatAddComposePractice") {
        type = "composePractice";
      } else if (this.$route.name === "SatAddAdaptive") {
        type = "adaptive";
      }
      return type;
    },
    ...mapState("user", ["token", "lang"]),
    isLogin() {
      return this.token !== "";
    },
    theme() {
      return Theme.name;
    }
  },
  watch: {},

  async mounted() {
    if (this.isLogin) {
      // const subjects = await SAT.getSubjects();
      // this.subjects = subjects.sat_subjects;
    } else {
      if (this.theme === "SAT") {
        this.$router.push({ name: "Login" });
      }
    }
  },

  methods: {
    createNew(formName) {
      const OLD_PRACTICE_READING = 1;
      const OLD_PRACTICE_WRITING = 2;
      const OLD_PRACTICE_MATH = 3;
      const OLD_PRACTICE_MATH_CALC = 4;
      const OLD_DRILL_READING = 5;
      const OLD_DRILL_WRITING = 6;
      const OLD_DRILL_MATH = 7;
      const OLD_DRILL_MATH_CALC = 8;
      const OLD_FULL_TEST = 9;
      const OLD_ADAPTIVE_PRACTICE = 10;

      const DRILL_PRACTICE_ENGLISH = 11;
      const DRILL_PRACTICE_MATH = 12;
      const MODULE_PRACTICE_ENGLISH = 13;
      const MODULE_PRACTICE_MATH = 14;
      const FULL_TEST = 15;
      const ADAPTIVE_PRACTICE = 16;

      this.$refs[formName].validate(async valid => {
        if (valid) {
          let name = "SatComposePracticeDetail";

          if (this.type == "composePractice") {
            if (this.isTeacher) {
              name = "TeacherSatComposePracticeDetail";
            }
            const res = await SAT.createComposePractice({
              compose_practice: {
                exam: {
                  title: this.newTest.title,
                  is_public: 0
                },
                is_new: this.newTest.isNew
              }
            });
            this.$router.push({
              name: name,
              query: { id: res.compose_practice.id, isEdit: 1 }
            });
          } else if (this.type == "adaptive") {
            const res = await SAT.createAdaptive({
              exam: {
                title: this.newTest.title,
                is_public: 0
              },
              is_new: this.newTest.isNew
            });
            this.$router.push({
              name: "SatAdaptiveDetail",
              query: { id: res.compose_practice.id, isEdit: 1 }
            });
          } else {
            let name = "SatPracticeDetail";
            let role = "teacher";
            if (this.isTeacher) {
              name = "TeacherSatPracticeDetail";
              role = "teacher";
            }
            if (this.isAdmin) {
              name = "SatPracticeDetail";
              role = "admin";
            }
            let res = null;
            res = await ExamActions.createExam(role, {
              category: this.newTest.subject,
              title: this.newTest.title
              // version: this.newTest.isNew
            });
            // if (this.$route.query.type == "drill") {
            //   if (this.isTeacher) {
            //     res = await ExamActions.createExam({
            //       practice: {
            //         exam: {
            //           title: this.newTest.title,
            //           is_public: 0
            //         },
            //         subject_type: "Modules\\SAT\\Entities\\SatSubject",
            //         subject_id: this.newTest.subject,
            //         is_new: this.newTest.isNew,
            //         type: "drill"
            //       }
            //     });
            //   } else if (this.isAdmin) {
            //     res = await SAT.createPractice({
            //       practice: {
            //         exam: {
            //           title: this.newTest.title,
            //           is_public: 0
            //         },
            //         subject_type: "Modules\\SAT\\Entities\\SatSubject",
            //         subject_id: this.newTest.subject,
            //         is_new: this.newTest.isNew,
            //         type: "drill"
            //       }
            //     });
            //   }
            // } else {
            //   res = await SAT.createPractice({
            //     practice: {
            //       exam: {
            //         title: this.newTest.title,
            //         is_public: 0
            //       },
            //       subject_type: "Modules\\SAT\\Entities\\SatSubject",
            //       subject_id: this.newTest.subject,
            //       is_new: this.newTest.isNew
            //     }
            //   });
            // }
            this.$router.push({
              name: name,
              query: { id: res.exam.test_id, isEdit: 1 }
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style scoped>
.box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding-top: 60px;
}
.box .left-menu {
  width: 200px;
  height: 100%;
}
.box .right-content {
  background: #fafafa;
}
.right-content-detail {
  width: inherit;
  margin-left: 72px;
  margin-right: 72px;
  margin-top: 20px !important;
  padding-bottom: 20px;
}
.button-group {
  padding: 1rem 20px;
}
::v-deep .el-menu {
  border: none;
}
::v-deep .button-group .el-button {
  width: 100%;
  margin: 0.5rem 0;
}
.right-content {
  flex: 1;
  height: 100%;
  overflow: auto;
}
</style>
